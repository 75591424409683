import React from "react";
import Layouts from "../../../Layouts";
import CarePlan from "../../../../Assets/Images/PersonalizeCare.jpg";
import Tick from "../../../../Assets/Images/Tick.png";
import BenifitsCare from "../../../../Assets/Images/Benifits-Care.jpg";
import Team1 from "../../../../Assets/Images/Team1.png";
import Team2 from "../../../../Assets/Images/Team2.png";
import Team3 from "../../../../Assets/Images/Team3.png";
import Team4 from "../../../../Assets/Images/Team4.png";
import Client1 from "../../../../Assets/Images/Client1.png";
import Client2 from "../../../../Assets/Images/Client2.png";
import Client3 from "../../../../Assets/Images/Client3.png";
import Client4 from "../../../../Assets/Images/Client4.png";
import Client5 from "../../../../Assets/Images/Client5.png";

const testimonials = [
  { name: "Sarah Johnson", role: "Granddaughter", image: Client1 },
  { name: "Michael Smith", role: "Client", image: Client2 },
  { name: "Emily Chen", role: "Daughter", image: Client3 },
  { name: "David Wilson", role: "Daughter", image: Client4 },
  { name: "David Wilson", role: "Daughter", image: Client5 },
];
const Testimonial = ({ name, role, image }) => (
  <div className="flex items-center space-x-4">
    <img
      className="w-12 h-12 rounded-full ring-2 ring-green-400"
      src={image}
      alt={name}
    />
    <div className="flex flex-col">
      <span className="font-semibold text-lg">{name}</span>
      <span className="text-sm text-gray-600">{role}</span>
    </div>
  </div>
);

const SeviceStatic = () => {
  return (
    <Layouts>
      <div className="container w-[90%] mx-auto bg-white">
        <div className="md:mt-10  rounded-2xl md:py-12 py-4 bg-[#e0f7ff] flex flex-col items-center">
          <h2
            className="md:text-[55px] text-xl text-[#023584] "
            style={{ fontWeight: 700 }}
          >
            Personalized <span className="text-[#266AFB]">Care</span> plan
          </h2>
          <div className="flex flex-col items-center  mt-10">
            <p className="text-[#023584] text-[16px]">
              Our personalized care plans offer tailored solutions to meet the{" "}
            </p>
            <p className="text-[#023584] text-[16px]">
              {" "}
              unique needs and preferences of each client.
            </p>
          </div>
          <div className="flex justify-center items-center mt-6">
            <button className="hover:bg-[#014B11] bg-[#023584]  font-medium text-white px-4 py-2 text-[14px] rounded-3xl ml-2 mt-1">
              Schedule your consultation
            </button>
          </div>
        </div>
        <div className="mt-8 ">
          <img src={CarePlan} alt="" className="rounded-2xl" />
        </div>
        <div className="text-center mt-6 text-[#023584]">
          <p className="text-[18px]">
            Our personalized care plans are designed to evolve with you,
            ensuring that as your needs change over time, so too does
          </p>
          <p className="text-[18px]">
            the level of care and support we provide. We believe in empowering
            you to live life on your terms, and our personalized
          </p>
          <p className="text-[18px]">
            care plans are just one way we strive to make that a reality.
          </p>
          <div className="w-[75%] mx-auto mt-8 flex justify-between ">
            <div className=" flex gap-3 items-center text-[18px] ">
              <img src={Tick} alt="" className="h-8 w-8" />
              <p>Customized care assessments</p>
            </div>
            <div className=" flex gap-3 items-center text-[18px] ">
              <img src={Tick} alt="" className="h-8 w-8" />
              <p>Flexible care options</p>
            </div>
            <div className=" flex gap-3 items-center text-[18px] ">
              <img src={Tick} alt="" className="h-8 w-8" />
              <p>Individualized care planning</p>
            </div>
          </div>
        </div>

        <div className="md:flex md:mt-20 mt-10">
          <div className="md:w-1/2 flex gap-8 relative ">
            <img src={BenifitsCare} alt="" className="rounded-2xl" />
          </div>
          <div className="md:w-1/2">
            <div className="md:pl-20">
              <h2
                className="md:text-[50px] text-xl text-[#023584] "
                style={{ fontWeight: 700 }}
              >
                Who can <span className="text-[#266AFB]">Benefit</span>
              </h2>
              <p className="text-[18px] text-[#023584] mt-4">
                Our values guide everything we do. We believe in Compassion,
                Integrity, Excellence, Respect, and Innovation.
              </p>
              <ul className=" text-[#023584] mt-6 ">
                <li className="flex gap-3 ">
                  <img src={Tick} alt="" className="h-6 w-6" />
                  <p>
                    Seniors who wish to age in place and maintain their
                    independence.
                  </p>
                </li>
                <li className="flex gap-3 mt-4">
                  <img src={Tick} alt="" className="h-6 w-6" />
                  <p>
                    Individuals recovering from illness, injury, or surgery who
                    require temporary assistance and support.
                  </p>
                </li>
                <li className="flex gap-3 mt-4">
                  <img src={Tick} alt="" className="h-6 w-6" />
                  <p>
                    People with chronic conditions or disabilities who need
                    ongoing care and supervision.
                  </p>
                </li>
                <li className="flex gap-3 mt-4">
                  <img src={Tick} alt="" className="h-6 w-6" />
                  <p>
                    Family caregivers who require respite and support in caring
                    for their loved ones.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-24">
          <h2
            className="md:text-[50px] text-xl text-center text-[#023584] "
            style={{ fontWeight: 700 }}
          >
            How it <span className="text-[#266AFB]">works</span>
          </h2>
          <div className="mt-20 flex justify-between gap-3 items-center text-[#023584]">
            <div className="flex flex-col justify-center mb-5 items-center">
              <div className="flex p-4  h-16 w-16 bg-[#E4FFEA] rounded-full justify-center items-center">
                <p className=" text-[24px] font-medium text-[#023584] ">01</p>
              </div>
              <p className="mt-3 font-semibold text-3xl text-[#023584]">
                Consultation
              </p>
              <p className="mt-3 text-[18px]">
                Thorough assessments conducted by our care{" "}
              </p>
              <p className="text-[18px]">
                team to understand your medical history
              </p>
            </div>
            <div className="flex flex-col justify-between items-center">
              <div className="flex p-4  h-16 w-16 bg-[#ECF2FF] rounded-full justify-center items-center">
                <p className=" text-[24px] font-medium text-[#023584] ">02</p>
              </div>
              <p className="mt-3 font-semibold text-3xl text-[#023584]">
                Customized care plan
              </p>
              <p className="mt-3 text-[18px]">
                A customized care plan is a personalized
              </p>
              <p className="text-[18px]">
                healthcare strategy designed to meet the
              </p>
              <p className="text-[18px]">unique needs of each patient.</p>
            </div>
            <div className="flex flex-col justify-between items-center">
              <div className="flex p-4  h-16 w-16 bg-[#E0F7FF] rounded-full justify-center items-center">
                <p className=" text-[24px] font-medium text-[#023584] ">03</p>
              </div>
              <p className="mt-3 font-semibold text-3xl text-[#023584]">
                Care Implementation
              </p>
              <p className="mt-3 text-[18px]">
                Schedule a consultation with our team to{" "}
              </p>
              <p className="text-[18px]">discuss your healthcare needs and</p>
              <p className="text-[18px]">preferences.</p>
            </div>
          </div>
        </div>
        <div className="mt-20">
          <h2
            className="md:text-[50px] text-2xl text-[#023584] "
            style={{ fontWeight: 500 }}
          >
            Meet our dedicated <span className="text-[#266AFB]">team</span>
          </h2>
          <div className="md:grid grid-cols-4 mt-6 gap-10 py-8">
            <div className="text-center">
              <div className="relative overflow-hidden rounded-2xl">
                <img
                  src={Team1}
                  alt="Michael Wilson"
                  className="w-full h-full object-cover duration-700 hover:rotate-6  hover:scale-110"
                />
              </div>
              <h3 className="text-[24px] font-semibold text-[#023584] mx-4">
                Michael Wilson, RN
              </h3>
              <p className="text-[#023684] font-normal text-[18px]">
                Senior Nurse
              </p>
            </div>
            <div className="text-center">
              <div className="relative overflow-hidden rounded-2xl">
                <img
                  src={Team2}
                  alt="Michael Wilson"
                  className="w-full h-full object-cover duration-700 hover:rotate-6  hover:scale-110"
                />
              </div>
              <h3 className="text-[24px] font-semibold text-[#023584] mx-4">
                Susie Beats, LPN
              </h3>
              <p className="text-[#023684] font-normal text-[18px]">
                Care Coordinator
              </p>
            </div>
            <div className="text-center">
              <div className="relative overflow-hidden rounded-2xl">
                <img
                  src={Team3}
                  alt="Michael Wilson"
                  className="w-full h-full object-cover duration-700 hover:rotate-6  hover:scale-110"
                />
              </div>
              <h3 className="text-[24px] font-semibold text-[#023584] mx-4">
                Serina Williams, RN
              </h3>
              <p className="text-[#023684] font-normal text-[18px]">
                Palliative Care
              </p>
            </div>
            <div className="text-center">
              <div className="relative overflow-hidden rounded-2xl">
                <img
                  src={Team4}
                  alt="Michael Wilson"
                  className="w-full h-full object-cover duration-700 hover:rotate-6  hover:scale-110"
                />
              </div>
              <h3 className="text-[24px] font-semibold text-[#023584] mx-4">
                Junitha Caarter, RN
              </h3>
              <p className="text-[#023684] font-normal text-[18px]">
                Nursing Assistant
              </p>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <div className="p-8 bg-blue-50 rounded-lg">
            <h2
              className="md:text-[50px] text-2xl mb-20 text-center text-[#023584] "
              style={{ fontWeight: 500 }}
            >
              Words from our <span className="text-[#266AFB]">clients</span>
            </h2>
            <div className="grid grid-cols-3 gap-4">
              {testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className={`p-4 rounded-lg ${
                    index === 0 ? "bg-green-100" : "bg-blue-100"
                  }`}
                >
                  <Testimonial {...testimonial} />
                </div>
              ))}
            </div>
            <div className="mt-8 p-4 bg-blue-100 rounded-lg text-center">
              <div className="flex justify-center mb-4">
                {[...Array(5)].map((_, i) => (
                  <svg
                    key={i}
                    className="w-6 h-6 text-blue-600"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 17.75l-6.364 3.67 1.832-7.84L2 8.94l7.932-.66L12 1l2.068 7.28L22 8.94l-5.468 4.64 1.832 7.84z" />
                  </svg>
                ))}
              </div>
              <p className="text-blue-900">
                Finding reliable care for my father was a priority, and Nursing
                exceeded our expectations. The caregivers were attentive,
                knowledgeable, and respectful of my father's dignity. Thanks to
                Nursing Care, my father was able to remain in the comfort of his
                own home.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-20 ">
        <div className=" rounded-2xl py-8 bg-[#e0f7ff] flex flex-col items-center">
          <h2
            className="md:text-[44px] text-[20px] text-[#023584] "
            style={{ fontWeight: 700 }}
          >
            Join our community of care
          </h2>
          <div className="md:flex md:flex-col text-center items-center mt-4">
            <p className="text-[#023584] text-[16px]">
              At Geri<span className="italic">e</span>Care, we're here to provide compassionate and
              personalized care{" "}
            </p>
            <p className="text-[#023584] text-[16px]">
              to you or your loved ones.
            </p>
          </div>
          <div className="flex justify-center items-center mt-6">
            <button className="hover:bg-[#014B11] bg-[#023584] font-medium w-24 text-white px-2 py-2 text-[14px] rounded-3xl ml-2 mt-1">
              Get started
            </button>
          </div>
        </div>
      </div>
      </div>
    </Layouts>
  );
};

export default SeviceStatic;
