import React, { useState } from "react";
import Layouts from "../../Layouts";
import Doctor1 from "../../../Assets/Images/Doctor1.png";
import Doctor2 from "../../../Assets/Images/Doctor2.png";
import Doctor3 from "../../../Assets/Images/Doctor3.png";
import Location from "../../../Assets/Images/location.png";
import Videocall from "../../../Assets/Images/video-call-48.png";
import { FcVideoCall } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

const BookAnAppointment = () => {
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();

  const handleRoute = (route) => {
    navigate(route);
  };

  const doctors = [
    {
      name: "Mukesh Mirisha",
      specialty: "Gastroenterology",
      experience: "8 years of exp.",
      location: "Chennai",
      fee: "₹300.00",
      image: Doctor1,
      videoCallIcon: Videocall,
      locationIcon: Location,
    },
    {
      name: "Rajiv Khandelwal",
      specialty: "Cardiology",
      experience: "10 years of exp.",
      location: "Mumbai",
      fee: "₹500.00",
      image: Doctor2,
      videoCallIcon: Videocall,
      locationIcon: Location,
    },
    {
      name: "Siddarth Desai",
      specialty: "Neurology",
      experience: "7 years of exp.",
      location: "Delhi",
      fee: "₹400.00",
      image: Doctor3,
      videoCallIcon: Videocall,
      locationIcon: Location,
    },
    {
      name: "Mukesh Mirisha",
      specialty: "Gastroenterology",
      experience: "8 years of exp.",
      location: "Chennai",
      fee: "₹300.00",
      image: Doctor1,
      videoCallIcon: Videocall,
      locationIcon: Location,
    },
    {
      name: "Rajiv Khandelwal",
      specialty: "Cardiology",
      experience: "10 years of exp.",
      location: "Mumbai",
      fee: "₹500.00",
      image: Doctor2,
      videoCallIcon: Videocall,
      locationIcon: Location,
    },
    {
      name: "Anita Desai",
      specialty: "Neurology",
      experience: "7 years of exp.",
      location: "Delhi",
      fee: "₹400.00",
      image: Doctor3,
      videoCallIcon: Videocall,
      locationIcon: Location,
    },
    {
      name: "Mukesh Mirisha",
      specialty: "Gastroenterology",
      experience: "8 years of exp.",
      location: "Chennai",
      fee: "₹300.00",
      image: Doctor1,
      videoCallIcon: Videocall,
      locationIcon: Location,
    },
    {
      name: "Rajiv Khandelwal",
      specialty: "Cardiology",
      experience: "10 years of exp.",
      location: "Mumbai",
      fee: "₹500.00",
      image: Doctor2,
      videoCallIcon: Videocall,
      locationIcon: Location,
    },
    {
      name: "Siddarth Desai",
      specialty: "Neurology",
      experience: "7 years of exp.",
      location: "Delhi",
      fee: "₹400.00",
      image: Doctor3,
      videoCallIcon: Videocall,
      locationIcon: Location,
    },
    // Add more doctor objects as needed
  ];



  const DoctorCard = ({ doctor }) => (
    <div className="mt-2 gap-5 p-5 bg-white shadow-md border rounded-lg">
      <div className="flex justify-center gap-4">
        <div className="flex justify-center mt-3 items-center w-[90px] h-[90px] p-2 bg-gradient-to-r from-[#023584] to-[#266AFB] rounded-lg hover:-translate-y-4 duration-700 hover:scale-110">
          <img src={doctor.image} alt="" className="h-20 w-20" />
        </div>
        <div className="space-y-2">
          <div className="flex gap-6 justify-center items-center sm:justify-between">
            <h2 className="text-xl font-semibold">{doctor.name}</h2>
            <img src={doctor.videoCallIcon} alt="" className="h-8 w-8" />
          </div>
          <p className="text-sm text-gray-500">{doctor.specialty}</p>
          <p className="text-sm text-gray-500">{doctor.experience}</p>
          <div className="flex gap-6 items-center justify-between">
            <div className="flex text-[12px]">
              <img src={doctor.locationIcon} alt="" className="h-4 w-4 mr-1" />
              <p>{doctor.location}</p>
            </div>
            <p className="text-green-500 font-bold text-lg">{doctor.fee}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-3">
        <button className="bg-blue-600 py-1 px-2 rounded-md text-white font-sm text-[14px] shadow-xl hover:ring-2 border-white hover:scale-75 duration-500">
          View Profile
        </button>
        <button className="border-2 ring-blue-500 py-1 px-2 rounded-md text-blue-600 font-sm shadow-lg hover:scale-75 duration-500" onClick={() => handleRoute("/doctors/profile")}>
          Book Consultation
        </button>
      </div>
    </div>
  );

  return (
    <Layouts>
      <div>
        <div className="container w-[90%] mx-auto">
          {/* <h2
            className="md:text-[40px] text-xl text-[#023584] text-center mb-4 "
            style={{ fontWeight: 700 }}
          >
            Book Your <span className="text-[#266AFB]">Appointment</span>
          </h2> */}
          {/* <h2>{showAll ? "s": "g" }</h2> */}
          {showAll ?  <h2
            className="md:text-[40px] text-xl text-[#023584] text-center mb-4 "
            style={{ fontWeight: 700 }}
          >
            Doctors <span className="text-[#266AFB]">List</span>
          </h2> : <h2
            className="md:text-[40px] text-xl text-[#023584] text-center mb-4 "
            style={{ fontWeight: 700 }}
          >
            Book Your <span className="text-[#266AFB]">Appointment</span>
          </h2> }
          <div>
            <div className="grid grid-cols-3 gap-4">
              {doctors
                .slice(0, showAll ? doctors.length : 3)
                .map((doctor, index) => (
                  <DoctorCard key={index} doctor={doctor} />
                ))}
            </div>
            <div className="flex justify-center mt-5">
              <button
                onClick={() => setShowAll(!showAll)}
                className="bg-blue-600 py-1 px-3 rounded-md text-white font-sm text-[14px] shadow-xl hover:ring-2 border-white hover:scale-75 duration-500"
              >
                {showAll ? "See Less" : "See All"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default BookAnAppointment;
