import React from 'react';
import oldlady from '../../../Assets/Images/oldlady.png';
import experience from "../../../Assets/Images/experience.svg";
import flexible from "../../../Assets/Images/flexible.svg";

const WhyChooseComponent = () => {
  return (
    <div className="container mx-auto md:my-20 my-10 md:w-[90%] px-4">
      <div className="flex flex-col md:flex-row items-center">
        <div className="md:w-1/2">
          <h2 className="md:text-5xl text-2xl font-bold mb-4 text-[#003588]">Why choose<span className="text-[#266AFB]"> Geri<span className="text-[#266AFB] italic  font-medium">e</span>Care?</span> 
          </h2>
          <p className="mb-6 text-[#003588] text-base">We uphold the highest standards of medical excellence and patient safety, ensuring that every patient receives personalized attention and support throughout their healthcare journey.</p>
          <ul>
            <li className="flex items-center mb-4">
              <span className="bg-[#E4FFEA] p-4 rounded-full mr-4">
                {/* <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c1.104 0 2-.896 2-2s-.896-2-2-2-2 .896-2 2 .896 2 2 2zm0 4c-2.784 0-8 1.392-8 4v2h16v-2c0-2.608-5.216-4-8-4z" />
                </svg> */}
                <img src={experience} alt="" />
              </span>
              <div>
                <h3 className="md:text-3xl text-2xl font-semibold text-[#003588]">Experienced team</h3>
                <p className='text-[#003588] mt-3'>Our caregivers are trained professionals who truly care about your well-being.</p>
              </div>
            </li>
            <li className="flex items-center">
              <span className="bg-[#E4FFEA] p-4 rounded-full mr-4">
                {/* <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m-8 4h6m-6 4h4M3 5h12l4 6H9l-6 6h12"></path>
                </svg> */}
                <img src={flexible} alt="" />
              </span>
              <div>
                <h3 className="md:text-3xl text-2xl font-semibold text-[#003588]">Flexible scheduling</h3>
                <p className='text-[#003588] mt-3'>We offer flexible scheduling to fit your lifestyle.</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="md:w-1/2 mt-6 md:mt-0 md:ml-10">
          <img src={oldlady} alt="Nursing Care" className="" />
        </div>
      </div>
    </div>
  );
};

export default WhyChooseComponent;
