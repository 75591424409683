import React from 'react';
import servicehome from '../../../Assets/Images/Home service.png';


const HomeCareSection = () => {
  return (
    <div className="bg-[#E0F7FF] flex flex-col md:flex-row items-center justify-between p-8 rounded-lg container w-[90%] mx-auto mt-4">
    <div className="md:w-1/2">
      <h2 className="md:text-4xl text-2xl font-bold text-blue-900 mb-4">
        Comprehensive home care <span className="text-blue-500">services</span>
      </h2>
      <p className="text-blue-700">
        Discover a range of services designed to provide compassionate and professional care in the comfort of your home.
      </p>
    </div>
    <div className="md:w-1/2 mt-8 md:mt-0">
      <img
        src={servicehome}
        alt="Home Care"
        className=""
      />
    </div>
  </div>
  );
};

export default HomeCareSection;
