import React from "react";
import Geri_care from "../Components/Pages/Pre-login/HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "../Components/Pages/Pre-login/About";
import Contact from "../Components/Pages/Pre-login/Contact/Contact";
import Service from "../Components/Pages/Services/Index";
import BookAnAppointment from "../Components/Pages/Services/BookAnAppointment";
import DoctorsProfile from "../Components/Pages/Services/DoctorsProfile";
import ServiceStatic from "../Components/Pages/Pre-login/ReadMore/index";

function PageRouting() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Geri_care />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service/book-an-appointment" element={<BookAnAppointment/>} />
        <Route path="/doctors/profile" element={<DoctorsProfile/>} />
        <Route path="/service-static" element={<ServiceStatic/>} />

      </Routes>
    </BrowserRouter>
  );
}

export default PageRouting;
