import React, { useState } from "react";
import Nursing from "../../../../Assets/Images/Nursing_Care.png";
import Clock from "../../../../Assets/Images/Clock.png";
import Nurse from "../../../../Assets/Images/Nurse.png";
import Caring from "../../../../Assets/Images/Caring.jpg";


const NursingCare = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "Specialized Care Professionals",
      answer:
        "Our team is comprised of highly trained and experienced healthcare professionals, including doctors, nurses, therapists, and caregivers who specialize in elderly care.We continuously update our knowledge and skills to provide the best possible care for our clients",
    },
    {
      question: "Individualized Care Plans",
      answer:
        "We create personalized care plans tailored to the specific needs, preferences, and goals of each client. Our care plans are flexible and can be adjusted as needs change over time.",
    },
    {
      question: "Compassionate Caregivers",
      answer:
        "Our caregivers are not only skilled but also compassionate and dedicated to providing respectful and empathetic care. We treat every client with the dignity and respect they deserve.",
    },
  ];

  return (
    <div>
      <div className="bg-[#EDF2FB] relative ">
        <div className="container w-[90%] mx-auto md:flex pb-20">
          <div className="md:w-1/2 md:mt-24">
            <h2
              className="md:text-[50px] text-xl  text-[#023584] "
              style={{ fontWeight: 700 }}
            >
              Why choose <span className="text-[#266AFB]">Geri<span className="text-[#266AFB] italic  font-medium">e</span>Care?</span>
            </h2>

            <div className="space-y-6 mt-16 md:pr-16">
      {faqs.map((faq, index) => (
        <div
          key={index}
          className="bg-white p-4 rounded-lg shadow-sm transition-all duration-300"
        >
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => handleToggle(index)}
          >
            <span className="text-lg font-semibold text-[#023584]">
              {faq.question}
            </span>
            <span className="text-[#023584] font-bold text-xl">
              {activeIndex === index ? "-" : "+"}
            </span>
          </div>
          <div
            className={`overflow-hidden transition-all duration-1000 ${
              activeIndex === index ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
            }`}
          >
            <div className="mt-2 text-[#023584] text-sm">
              {faq.answer}
            </div>
          </div>
        </div>
      ))}
    </div>
          </div>
          <div className="md:w-1/2  mt-20">
            <img src={Caring} alt="" className="rounded-2xl" />
          </div>
        </div>
        {/* <div className="absolute w-[80px] h-[75px] bg-white top-0 right-0 rounded-bl-2xl "></div> */}
        <div className="md:absolute -bottom-6 p-2 right-12 bg-white md:rounded-2xl flex justify-between">
          <div className="flex justify-between gap-32">
            <div className="md:flex items-center">
              <img src={Clock} alt="" className="w-8 h-8 mr-2" />
              <span className="text-[#023584] text-[16px] font-semibold">
                24/7 availability <br />
                <span className="text-[12px] text-[#023584]">
                  For your peace of mind
                </span>
              </span>
            </div>
            <div className="md:flex items-center">
              <img src={Nurse} alt="" className="w-8 h-8 mr-2" />
              <span className="text-[#023584] text-[16px] font-semibold">
                Professional expertise <br />
                <span className="text-[12px] text-[#023584]">
                  Specialized skills in a profession
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20 relative container w-[90%] mx-auto">
        <div className=" rounded-2xl py-8 bg-[#e0f7ff] flex flex-col items-center">
          <h2
            className="md:text-[40px] text-[20px] text-[#023584] "
            style={{ fontWeight: 700 }}
          >
            Join our community of care
          </h2>
          <div className="md:flex md:flex-col text-center items-center mt-4">
            <p className="text-[#023584] text-[14px]">
              At Geri<span className="italic">e</span>Care, we're here to provide compassionate and
              personalized care{" "}
            </p>
            <p className="text-[#023584] text-[14px]">
              to you or your loved ones.
            </p>
          </div>
          <div className="flex justify-center items-center mt-6">
            <button className="hover:bg-[#014B11] bg-[#023584] w-24 text-white px-2 py-2 text-[14px] rounded-3xl ml-2 mt-1">
              Get started
            </button>
          </div>
        </div>
        {/* <div className="absolute w-[70px] h-[70px] bg-white top-0 left-0 rounded-br-2xl "></div>
        <div className="absolute w-[70px] h-[70px] bg-white bottom-0 right-0 rounded-tl-2xl "></div> */}
      </div>
    </div>
  );
};

export default NursingCare;
