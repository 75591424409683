import React from 'react';
import HomeCareSection from './HomeCareSection';
import ServicesComponent from './ServicesComponent';
import WhyChooseComponent from './WhyChooseComponent';
import PricingComponent from './PricingComponent';
import JoinCommunity from './JoinCommunity';
import Layouts from '../../Layouts';

const Service = () => {
  return (
    <Layouts>
    <div className=''>
        <HomeCareSection />
        <ServicesComponent />
        <JoinCommunity />
        <WhyChooseComponent />
        <PricingComponent />
    </div>
    </Layouts>
  );
};

export default Service;
