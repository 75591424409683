import React from "react";
import Header from "../shared/Header";
import Footer from "../shared/Footer";

function Layouts({ children }) {
  return (
    <>
      <Header />
      <div>{children}</div>
      <Footer />
    </>
  );
}

export default Layouts;
