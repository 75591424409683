import React from "react";

const PricingComponent = () => {
  return (
    <div>
    <div className="container mx-auto my-10 px-4 w-[90%]">
      <h2 className="md:text-5xl text-2xl font-bold text-center md:mb-8 mb-4 text-[#003588]">
        Pricing
      </h2>
      <div className="grid md:grid-cols-3 gap-6">
        {/* Hourly Care Plan */}
        <div className="bg-[#ECF2FF] p-6 rounded-3xl relative md:h-5/6 h-full">
          <h3 className="text-xl font-semibold mb-4 text-[#266AFB]">Hourly Care Plan</h3>
          <p className="md:text-6xl text-3xl font-bold mb-4 text-[#003588]">
          ₹1900<span className="text-lg font-medium">/hour</span>
          </p>
          <div className="text-center flex flex-col justify-center items-center p-5">
          <ul className="list-disc  text-left mb-4 leading-normal">
            <li className="mb-3 text-[#003588] text-base">Personal care assistance</li>
            <li className="mb-3 text-[#003588]">Medication reminders</li>
            <li className="mb-3 text-[#003588]">Meal preparation and feeding assistance</li>
            <li className="mb-3 text-[#003588]">Companionship and emotional support</li>
          </ul>
          </div>
          <button className="bg-[#003588] text-white py-2 px-4 font-bold text-lg w-full rounded-full">
            Book now
          </button>
          {/* <div className="absolute w-[50px] h-[50px] bg-white top-0 right-0 rounded-bl-2xl"></div> */}
        </div>

        {/* Monthly Care Plan */}
        <div className="bg-green-100 p-6 rounded-3xl relative">
          <h3 className="text-xl font-semibold mb-4 text-[#266AFB]">Monthly Care Plan</h3>
          <p className="md:text-6xl text-3xl font-bold mb-4 text-[#003588]">
          ₹9900<span className="text-lg font-medium">/Month</span>
          </p>
          <div className="text-center flex flex-col justify-center items-center p-5">
          <ul className="list-disc  text-left mb-4 leading-normal">
          <li className="mb-3 text-[#003588] text-base">Personal care assistance</li>
          <li className="mb-3 text-[#003588] text-base">Medication reminders</li>
          <li className="mb-3 text-[#003588] text-base">Meal preparation and feeding assistance</li>
          <li className="mb-3 text-[#003588] text-base">Companionship and emotional support</li>
          <li className="mb-3 text-[#003588] text-base">Transportation to appointments</li>
          <li className="mb-3 text-[#003588] text-base">Respite care for family caregivers (X hours per month)</li>
          </ul>
          </div>
          <button className="bg-[#003588] font-bold text-lg text-white py-2 px-4 rounded-full w-full">
            Get started
          </button>
          {/* <div className="absolute w-[50px] h-[50px] bg-white top-0 right-0 rounded-bl-2xl"></div> */}

        </div>

        {/* Customized Care Plan */}
        <div className="bg-[#ECF2FF] p-6 rounded-3xl relative md:h-5/6 h-full">
          <h3 className="text-xl font-semibold mb-4 text-[#266AFB]">Customized Care Plan</h3>
          <p className="mb-4 text-[#003588] text-base">
            At Geri<span className="italic">e</span>Care, we understand that every individual has unique care
            needs.
          </p>
          <div className="text-center flex flex-col justify-center items-center p-5">
          <ul className="list-disc  text-left mb-2 leading-normal">
            <li className="mb-3 text-[#003588] text-base">
              Personalized care plan designed to meet your individual needs and
              preferences
            </li>
            <li className="mb-3 text-[#003588] text-base">
              Flexible scheduling options to accommodate your schedule and
              routines
            </li>
            <li className=" text-[#003588] text-base">Transparent pricing with no hidden fees or surprises</li>
          </ul>
          </div>
          <button className="bg-[#003588] font-bold text-lg text-white py-2 px-4 rounded-full w-full">
            Contact us
          </button>
          {/* <div className="absolute w-[50px] h-[50px] bg-white top-0 right-0 rounded-bl-2xl"></div> */}
        </div>
      </div>
    </div>

    </div>
  );
};

export default PricingComponent;
