import logo from './logo.svg';
import './App.css';
import Geri_care from './Components/Pages/Pre-login/HomePage';
import PageRouting from './Page_Routing';

function App() {
  return (
    <div className="App">
      <PageRouting />
    </div>
  );
}

export default App;
