import React, { useState } from "react";
import logo from "../../Assets/Images/logo5.png";
import { useNavigate } from "react-router-dom";
import { IoMenuSharp } from "react-icons/io5";
import { Modal, Button, Form, Input, Radio } from "antd";

function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isHoverdownOpen, setIsHoverdownOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userType, setUserType] = useState("doctor");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // Add your form submission logic here
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const navigate = useNavigate();

  const handleRoute = (route) => {
    navigate(route);
  };

  // const handleRedirect = () => {
  //   window.location.href = "https://www.doxzon.com/";
  // };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleHoverDropdown = () => {
    setIsHoverdownOpen(!isHoverdownOpen);
  };

  return (
    <div className="Header shadow-md mb-6 bg-[#FDFDFD] ">
      <div className="container w-[90%] h-28  mx-auto flex justify-between items-center">
        <div className="flex justify-center items-center">
          <div>
            <img src={logo} alt="" className="md:h-16 md:w-16 h-10 w-10" />
          </div>
          <h2 className="ml-2 font-bold md:text-[32px] text-[24px] text-[#0167A9] mr-16">
            Geri<span className="text-[#72c0f5] italic  font-medium">e</span>
            Care
          </h2>
        </div>
        <div className="flex justify-center items-center">
          <nav className="md:block hidden">
            <div className="flex gap-8 text-[18px] font-medium">
              <span
                onClick={() => handleRoute("/")}
                className="hover:text-[#0082f3] cursor-pointer"
              >
                Home
              </span>
              <span
                onClick={() => handleRoute("/about")}
                className="hover:text-[#0082f3] cursor-pointer"
              >
                About
              </span>
              <div className="relative">
                <button
                  className="hover:text-[#0082f3] cursor-pointer"
                  onMouseOver={handleHoverDropdown}
                  onClick={() => handleRoute("/service")}
                >
                  Services
                </button>
                {isHoverdownOpen && (
                  <div className="absolute left-0 mt-2 w-44 z-10 rounded-md bg-[#ffffff]   shadow-lg">
                    <a
                      href=""
                      className="block px-4 py-1  hover:text-[#0082f3] text-base"
                      onClick={() =>
                        handleRoute("/service/book-an-appointment")
                      }
                    >
                      Consult Doctor
                    </a>
                  </div>
                )}
              </div>
              <span
                onClick={() => handleRoute("/contact")}
                className="hover:text-[#0082f3] cursor-pointer"
              >
                Contact
              </span>
            </div>
          </nav>
          <button
            className="hover:bg-[#014B11] md:block hidden bg-[#023584] w-24 text-white px-2 py-2 text-[14px] font-medium rounded-3xl ml-4 mt-1"
            onClick={showModal}
          >
            Login
          </button>
          <div className="md:hidden ml-4 block">
            <IoMenuSharp className="w-5 h-5" onClick={handleDropdownToggle} />
          </div>
          {isDropdownOpen && (
            <div className=" absolute top-20  right-5 w-44 z-10 rounded-md   bg-[#ecf2ff] border border-gray-400 shadow-lg">
              <a
                href=""
                onClick={() => handleRoute("/")}
                className="block px-4 py-1 hover:bg-gray-100 hover:text-[#0082f3]"
              >
                Home
              </a>
              <a
                href=""
                onClick={() => handleRoute("/about")}
                className="block px-4 py-1 hover:bg-gray-100 hover:text-[#0082f3]"
              >
                About
              </a>

              <a
                href=""
                onClick={() => handleRoute("/service")}
                className="block px-4 py-1 hover:bg-gray-100 hover:text-[#0082f3]"
              >
                Services
              </a>

              <a
                href=""
                onClick={() => handleRoute("/contact")}
                className="block px-4 py-1 hover:bg-gray-100 hover:text-[#0082f3]"
              >
                Contact
              </a>
              <a
                href="https://www.doxzon.com/"
                // onClick={handleRedirect}
                className="block py-1 hover:bg-gray-100 bg-blue-700  mt-2 mb-2 ml-1 font-bold text-center text-white rounded-2xl w-24 hover:text-[#0082f3]"
              >
                Login
              </a>
            </div>
          )}
        </div>
        <Modal
          title={
            <div className="flex justify-center items-center font-bold text-xl">
              Login
            </div>
          }
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={""}
          className=""
        >
          <form class="flex flex-col">
            <Radio.Group
              onChange={(e) => setUserType(e.target.value)}
              value={userType}
              className="mb-4"
            >
              <Radio value="doctor">Doctor</Radio>
              <Radio value="patient">Patient</Radio>
            </Radio.Group>
            <label htmlFor="">User ID</label>
            <input
              placeholder="Email address"
              class=" text-gray-800 border rounded-md p-2 mb-4  focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
              type="email"
              aria-label="Enter Email "
            />
            <label htmlFor="">Password</label>
            <input
              placeholder="Enter Password"
              class=" text-gray-800 border rounded-md p-2 mb-4  focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
              type="password"
              aria-label="Password"
            />
            <div class="flex items-center justify-end flex-wrap mb-4">
              {/* <label
                class="text-sm text-gray-600 cursor-pointer flex items-center"
                for="remember-me"
              >
                <input class="mr-2" id="remember-me" type="checkbox" />
                Remember me
              </label> */}
              <a class="text-sm text-blue-500 hover:underline mb-0.5" href="#">
                Forgot password?
              </a>
            </div>
            <button
              class="bg-gradient-to-r from-indigo-500 to-blue-500 text-white font-bold py-2 px-4 rounded-md hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150"
              type="submit"
            >
              Login
            </button>
            <p class=" mt-4 ">
              Don't have an account?
              <a class="text-sm text-blue-500 hover:underline ml-1" href="#">
                Signup
              </a>
            </p>
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default Header;
