import React from "react";
import Doctor from "../../../../Assets/Images/Doctor.png";
import Doctor1 from "../../../../Assets/Images/Doctor1.png";
import Doctor2 from "../../../../Assets/Images/Doctor2.png";
import Doctor3 from "../../../../Assets/Images/Doctor3.png";
import Client1 from "../../../../Assets/Images/Client1.png";
import Client2 from "../../../../Assets/Images/Client2.png";
import Client3 from "../../../../Assets/Images/Client3.png";
import Client4 from "../../../../Assets/Images/Client4.png";
import Client5 from "../../../../Assets/Images/Client5.png";
import Grandpa from "../../../../Assets/Images/patient-grandpa.png";
import RoundLetter from "../../../../Assets/Images/RoundLetter.png";
import TenPlus from "../../../../Assets/Images/10+.png";

const About = () => {
  const clientImages = [Client1, Client2, Client3];

  const stats = [
    { number: "300", label: "Patients served" },
    { number: "30", label: "Caregivers" },
    { number: "10", label: "Services offered" },
    { number: "95%", label: "Client satisfaction" },
  ];

  return (
    <div>
      <div className="container w-[90%] mx-auto">
        <div className="md:flex">
          <div className="md:w-1/2 flex gap-8 relative">
            <div className="md:flex items-end">
              <div className="md:mb-10">
                <img src={Doctor} alt="" />
              </div>
            </div>
            <div>
              <img src={Grandpa} alt="" className="rounded-br-3xl" />
            </div>
            <div className="absolute -right-4 -bottom-4 z-10 bg-white md:h-[150px] h-[80px] w-[80px] md:w-[150px] rounded-full"></div>
            <div className="absolute -right-4 -bottom-4 z-20 rotation">
              <img src={RoundLetter} alt="" className="md:h-[150px] h-[80px] md:w-[150px] w-[80px] " />
            </div>
            <div className="absolute md:right-8 right-2 bottom-4 md:bottom-12 z-20 ">
              <img src={TenPlus} alt="" className="md:h-[30px] h-[15px] md:w-[50px] w-[30px] " />
            </div>
          </div>
          <div className="md:w-1/2 ">
            <div className="md:pl-20">
              <p className="bg-gradient-to-r from-[#B5EBFF] to-[#fff] px-2  w-28 py-1  text-[14px] text-[#023584]  rounded-2xl">
                Who we are
              </p>
              <h2
                className="md:text-[40px] text-[20px] text-[#023584] mt-2"
                style={{ fontWeight: 600 }}
              >
                About <span className="text-[#266AFB]">Geri<span className="text-[#266AFB] italic  font-medium">e</span>Care</span>
              </h2>
              <p className="text-[14px] text-[#023584] mt-2">
              Welcome to Geri<span className="italic">e</span>care, where our mission is to provide exceptional and compassionate care for the elderly. Our comprehensive approach to elderly care ensures that each individual receives the support, respect, and attention they deserve
              </p>
              <h3 className="text-[#023584] font-semibold text-[24px] mt-4">Our Vision</h3>
              <p className="text-[14px] text-[#023584] mt-2">
              We envision a world where every elderly person receives the care and respect they deserve. Our goal is to be a leader in elderly care, recognized for our excellence in service, innovation, and commitment to the well-being of our clients.
              </p>
              <div className="flex justify-end items-center mt-3">
                <button className="bg-[#014B11] hover:bg-[#023584] w-24 text-white px-2 py-2 text-[14px] rounded-3xl ">
                  Learn more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container w-[90%] mx-auto mt-20">
        <div className="md:flex ">
          <div className="md:w-1/2">
            <div className="relative bg-green-100 p-8 rounded-xl flex-1 mr-4">
              <h2 className="md:text-[36px] text-[20px] font-bold text-blue-800 mb-6">
                Meet our dedicated <span className="text-blue-600">team</span>
              </h2>

              <div className="doctors  overflow-hidden py-6 whitespace-nowrap">
                <div className="doctors-slide whitespace-nowrap flex">
                  <div className="text-center ">
                    <img
                      src={Doctor1}
                      alt="Michael Wilson"
                      className="w-32 h-32 rounded-2xl mx-4 hover:scale-105  duration-700"
                    />
                    <h3 className="text-[16px] font-semibold text-[#023584] mx-2">
                      Michael Wilson, RN
                    </h3>
                    <p className="text-[#023584] text-[12px]">
                      Nursing Specialist
                    </p>
                  </div>
                  <div className="text-center">
                    <img
                      src={Doctor2}
                      alt="Judy Nguyen"
                      className="w-32 h-32 rounded-2xl hover:scale-105  duration-700  mx-4"
                    />
                    <h3 className="text-[16px] font-semibold text-[#023584] mx-4">
                      Judy Nguyen, ER
                    </h3>
                    <p className="text-[#023584] text-[12px]">Emergency Room</p>
                  </div>
                  <div className="text-center">
                    <img
                      src={Doctor3}
                      alt="Billy Vasquez"
                      className="w-32 h-32  rounded-2xl hover:scale-105  duration-700 mx-4"
                    />
                    <h3 className="text-[16px] font-semibold text-[#023584] mx-4">
                      Billy Vasquez, NP
                    </h3>
                    <p className="text-[#023584] text-[12px]">
                      Nurse Practitioner
                    </p>
                  </div>
                  <div className="text-center">
                    <img
                      src={Doctor1}
                      alt="Michael Wilson"
                      className="w-32 h-32 rounded-2xl hover:scale-105  duration-700 mx-4 "
                    />
                    <h3 className="text-[16px] font-semibold text-[#023584] mx-2">
                      Michael Wilson, RN
                    </h3>
                    <p className="text-[#023584] text-[12px]">
                      Nursing Specialist
                    </p>
                  </div>
                  <div className="text-center">
                    <img
                      src={Doctor2}
                      alt="Judy Nguyen"
                      className="w-32 h-32 rounded-2xl hover:scale-105  duration-700 mx-4"
                    />
                    <h3 className="text-[16px] font-semibold text-[#023584] mx-4">
                      Judy Nguyen, ER
                    </h3>
                    <p className="text-[#023584] text-[12px]">Emergency Room</p>
                  </div>
                  <div className="text-center ">
                    <img
                      src={Doctor3}
                      alt="Billy Vasquez"
                      className="w-32 h-32  rounded-2xl mx-4 hover:scale-105  duration-700"
                    />
                    <h3 className="text-[16px] font-semibold text-[#023584] mx-4">
                      Billy Vasquez, NP
                    </h3>
                    <p className="text-[#023584] text-[12px]">
                      Nurse Practitioner
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-8 ">
                <button className="bg-[#014B11] hover:bg-[#023584] text-white font-medium py-2 px-4 rounded-full">
                  View all nurses
                </button>
              </div>
              {/* <div className="absolute w-[70px] h-[70px] bg-white bottom-0 right-0 rounded-tl-2xl "></div> */}
            </div>
          </div>
          <div className="md:w-1/2">
            <div className="relative bg-blue-100 md:p-8 p-4 md:mt-0 mt-4  rounded-xl md:flex-1 md:ml-4">
              <h2 className="md:text-3xl text-xl font-bold text-center mt-4 text-blue-800 mb-6">
                What our clients <span className="text-blue-500">say</span>
              </h2>
              <div className="text-center">
                <div className="flex justify-center mt-20">
                  {[...Array(5)].map((_, i) => (
                    <svg
                      key={i}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      className="w-6 h-6 text-blue-500"
                    >
                      <path d="M12 17.27l-5.18 2.73 1-5.91L3 9.24l5.91-.86L12 3.5l2.09 4.88 5.91.86-4.27 4.85 1 5.91L12 17.27z" />
                    </svg>
                  ))}
                </div>
                <p className="my-4 text-[#023584]">
                  The environment created by the Geri<span className="italic">e</span>Care team was one of
                  healing and positivity. Their holistic approach made a
                  significant difference in my recovery.
                </p>
                <p className="font-semibold text-[#023584]">Louis Ferguson</p>
                <div className="md:flex  justify-center items-center grid grid-cols-3 gap-10 mt-10 ">
                  {clientImages.map((src, i) => (
                    <img
                      key={i}
                      src={src}
                      alt={`Client ${i + 1}`}
                      className="w-10 h-10 hover:w-16 hover:h-16  hover:border-4 hover:border-cyan-400  rounded-xl hover:rounded-full  hover:scale-110 transition duration-500 ease-in-out  hover:duration-300"
                    />
                  ))}
                </div>
              </div>
              {/* <div className="absolute w-[70px] h-[70px] bg-white top-0 left-0 rounded-br-2xl "></div> */}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="md:py-16 py-10 p-4 md:p-0">
          <div className="max-w-6xl mx-auto md:px-4">
            <div className="grid grid-cols-4 gap-8 text-center">
              {stats.map((stat, index) => (
                <div key={index} className="flex flex-col items-center">
                  <span className="md:text-4xl text-2xl font-bold text-blue-800">
                    {stat.number}
                  </span>
                  <span className="text-blue-600 mt-2 text-sm">
                    {stat.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
