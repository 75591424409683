import React from 'react';
import personalcare from '../../../Assets/Images/personalcare.png';
import specialized from "../../../Assets/Images/specialized.svg";
import homenurse from "../../../Assets/Images/homenurse.svg";
import companion from "../../../Assets/Images/companion.svg";
import rehab from "../../../Assets/Images/rehab.svg";
import palliative from "../../../Assets/Images/palliative.svg";

const ServicesComponent = () => {
  return (
    <div className="container mx-auto my-10 w-[90%]">
      {/* <h2 className="text-3xl font-bold text-center mb-8">Our Services</h2> */}
      <div className="grid md:grid-cols-3 gap-8">
        <div className="bg-[#EDF2FB] p-6 rounded-3xl hover:bg-green-100 md:h-80">
          <div className="mb-4">
            <span className="inline-block bg-[#B2EAFF] text-white p-4 rounded-full">
              <img src={personalcare} alt="" className='w-10 h-10'/>
            </span>
          </div>
          <div>
          <h3 className="md:text-3xl text-xl font-semibold mb-4 text-[#003588]">Personal care assistance</h3>
          <p className='text-[#003588]'>Helping with daily activities to maintain independence.</p>
          <p className="text-[#003588] font-semibold mt-5">Learn more</p>
          </div>
        </div>
        <div className="bg-[#EDF2FB] p-6 rounded-3xl hover:bg-green-100 md:h-80">
          <div className="mb-4">
            <span className="inline-block bg-[#B2EAFF] text-white p-4 rounded-full">
            <img src={specialized} alt="" className='w-10 h-10'/>
            </span>
          </div>
          <div>
          <h3 className="md:text-3xl text-xl font-semibold mb-4 text-[#003588]">Specialized care</h3>
          <p className='text-[#003588] text-base'>Tailored care for specific medical <br/> needs.</p>
          <p className="text-[#003588] font-semibold mt-5">Learn more</p>
          </div>
        </div>
        <div className="bg-[#EDF2FB] p-6 rounded-3xl hover:bg-green-100 md:h-80">
          <div className="mb-4">
            <span className="inline-block bg-[#B2EAFF] text-white p-4 rounded-full">
            <img src={homenurse} alt="" className='w-10 h-10'/>
            </span>
          </div>
          <div>
          <h3 className="md:text-3xl text-xl font-semibold mb-4 text-[#003588]">Home nursing services</h3>
          <p className='text-[#003588] text-base'>Professional Geri<span className="italic">e</span>care is provided at home.</p>
          <p className="text-[#003588] font-semibold mt-5">Learn more</p>
        </div>
        </div>
        <div className="bg-[#EDF2FB] p-6 rounded-3xl hover:bg-green-100 md:h-80">
          <div className="mb-4">
            <span className="inline-block bg-[#B2EAFF] text-white p-4 rounded-full">
            <img src={companion} alt="" className='w-10 h-5'/>
            </span>
          </div>
          <div>
          <h3 className="md:text-3xl text-xl font-semibold mb-4 text-[#003588]">Companionship services</h3>
          <p className='text-[#003588] text-base'>Enhancing quality of life through social interaction.</p>
          <p className="text-[#003588] font-semibold mt-5">Learn more</p>
        </div>
        </div>
        <div className="bg-[#EDF2FB] p-6 rounded-3xl hover:bg-green-100 md:h-80">
          <div className="mb-4">
            <span className="inline-block bg-[#B2EAFF] text-white p-4 rounded-full">
            <img src={rehab} alt="" className='w-10 h-10'/>
            </span>
          </div>
          <div>
          <h3 className="md:text-3xl text-xl font-semibold mb-4 text-[#003588]">Rehabilitation support</h3>
          <p className='text-[#003588] text-base'>Individualized care, safety, familiarity, emotional support.</p>
          <p className="text-[#003588] font-semibold mt-5">Learn more</p>
        </div>
        </div>
        <div className="bg-[#EDF2FB] p-6 rounded-3xl hover:bg-green-100 md:h-80">
          <div className="mb-4">
            <span className="inline-block bg-[#B2EAFF] text-white p-4 rounded-full">
            <img src={palliative} alt="" className='w-10 h-10'/>
            </span>
          </div>
          <div>
          <h3 className="md:text-3xl text-xl font-semibold mb-4 text-[#003588]">Palliative care services</h3>
          <p className='text-[#003588] text-base'>Comfort, relief, support, improved quality of life.</p>
          <p className="text-[#003588] font-semibold mt-5">Learn more</p>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesComponent;
