import React from 'react'

const BookAppointment = () => {
  return (
    <div>
      <div className="bg-[#EDF2FB] relative rounded-tl-2xl">
      <div className="container w-[90%] mx-auto flex flex-col lg:flex-row p-4 lg:p-10">
        <div className="bg-[#FAFCFF] rounded-lg shadow-lg overflow-hidden w-full lg:w-[50%] mt-4 lg:mt-10">
          <div className="p-6 lg:p-8">
            <h2 className="text-xl lg:text-2xl font-bold mb-4 lg:mb-6 text-[#003588]">
              Book appointment
            </h2>
            <form>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label
                    className="block text-[#003588] text-xs font-bold mb-2"
                    htmlFor="fullName"
                  >
                    Full name
                  </label>
                  <input
                    id="fullName"
                    type="text"
                    placeholder="Enter your name"
                    className="shadow appearance-none border rounded-lg w-full text-xs py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div>
                  <label
                    className="block text-[#003588] text-xs font-bold mb-2"
                    htmlFor="contactNumber"
                  >
                    Contact number
                  </label>
                  <input
                    id="contactNumber"
                    type="text"
                    placeholder="Phone number"
                    className="shadow appearance-none border rounded-lg w-full text-xs py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label
                    className="block text-[#003588] text-xs font-bold mb-2"
                    htmlFor="service"
                  >
                    Choose service
                  </label>
                  <select
                    id="service"
                    className="shadow appearance-none text-xs border rounded-lg w-full py-2 px-3 text-[#003588] leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option>Select services</option>
                    <option>Service 1</option>
                    <option>Service 2</option>
                  </select>
                </div>
                <div>
                  <label
                    className="block text-[#003588] text-xs font-bold mb-2"
                    htmlFor="location"
                  >
                    Location
                  </label>
                  <input
                    id="location"
                    type="text"
                    placeholder="Enter location"
                    className="shadow appearance-none border rounded-lg w-full py-2 px-3 text-xs text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label
                  className="block text-[#003588] text-xs font-bold mb-2"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  placeholder="Additional Information"
                  className="shadow appearance-none border rounded-lg w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32 text-xs"
                ></textarea>
              </div>
              <div className="mb-4 flex items-start">
                <input type="checkbox" id="privacyPolicy" className="mt-1" />
                <label
                  htmlFor="privacyPolicy"
                  className="ml-2 text-[#003588] text-xs font-medium"
                >
                  By submitting this form, you agree to our friendly{" "}
                  <a href="#" className="text-blue-500 underline">
                    privacy policy
                  </a>
                </label>
              </div>
              <button type="submit" className="bg-[#014B11] hover:bg-[#023584] w-24 text-white px-2 py-2 text-[14px] rounded-3xl ml-2 mt-1">
              Submit
              </button>
            </form>
          </div>
        </div>
        <div className="p-6 lg:p-8 w-full lg:w-[60%]">
          <div className="flex flex-col justify-center items-center mt-6 lg:mt-10">
            <h2 className="text-2xl lg:text-4xl font-bold mb-4 lg:mb-6 text-[#003588]">
              How we <span className="text-[#266AFB]">work</span>
            </h2>
            <div className="list-none space-y-4 lg:space-y-6 flex flex-col justify-start mt-3">
              <p className="text-[#7694C3] flex justify-start items-start text-lg lg:text-2xl">
                <span className="text-blue-500 font-bold mr-2">|</span>
                Consultation
              </p>
              <p className="text-[#7694C3] flex items-center text-lg lg:text-2xl">
                <span className="text-blue-500 font-bold mr-2">|</span>
                Care Implementation
              </p>
              <p className="text-[#7694C3] flex items-center text-lg lg:text-2xl">
                <span className="text-blue-500 font-bold mr-2">|</span>
                Customized care plan
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="h-[50px] lg:h-[75px] w-[50px] lg:w-[75px] absolute bg-white rounded-br-2xl top-0 left-0"></div>
      <div className="h-[50px] lg:h-[75px] w-[50px] lg:w-[75px] absolute bg-white rounded-tl-2xl bottom-0 right-0"></div> */}
    </div>

    </div>
  )
}

export default BookAppointment
