import React from "react";
import Bg_1 from "../../../../Assets/Images/bg-image1.jpg";
import Bg_2 from "../../../../Assets/Images/sample.jpg";
import Bg_3 from "../../../../Assets/Images/sample7.jpg";
import Bg_4 from "../../../../Assets/Images/sample6.jpg";
import EmotionalSupport from "../../../../Assets/svg/Emotional support.svg";
import PatientSafety from "../../../../Assets/svg/Enhanced patient safety.svg";
import QualifiedNurses from "../../../../Assets/svg/Highly qualified nurses.svg";
import PersonalCare from "../../../../Assets/svg/Personal care assistance.svg";
import Services from "./Services";
import NursingCare from "./NursingCare";
import About from "./About";
import BookAppointment from "./BookAppointment";
import Layouts from "../../../Layouts";

const Geri_care = () => {
  return (
    <Layouts className="">
      <div className="container w-[90%] mx-auto   relative">
        <div className="rounded-2xl brightness-50 overflow-hidden">
          <img src={Bg_3} alt="" className=" w-full h-full rounded-2xl hover:scale-105 hover:rotate-1 duration-700" />
        </div>
        <div className="absolute md:top-28 top-0 md:left-20 p-5">
          <p className="bg-gradient-to-r from-[#023584] to-[#66686D] pl-3  py-1 w-40 md:w-64 text-[10px] md:text-[14px] text-white rounded-2xl">
            Compassionate home  Geri<span className="italic">e</span>Care
          </p>
          <h2 className="md:text-[50px]  text-white" style={{ fontWeight: 650 }}>
          Home-based healthcare<br />
          tailored to your needs
          </h2>
          <p className="md:text-[14px]  text-[8px] text-white mt-2">
            At Geri<span className="italic">e</span>care, we understand the critical role that <br />
            caregivers play in the well-being of their loved ones.
          </p>
          <button className="bg-[#023584] hover:bg-[#014B11] font-medium  text-white px-4 py-2 text-[12px] md:text-[16px] rounded-3xl mt-4 md:mt-16">
            Learn more
          </button>
        </div>
        {/* <div className="absolute left-0 bottom-0  bg-white overflow-hidden w-[400px] h-12 rounded-tr-3xl">
          <div className="flex items-center  mt-3 letters  justify-center ">
            <div className="flex gap-12 letters-slide whitespace-nowrap">
            <div className="flex gap-3" >
              <img src={EmotionalSupport} alt="" className="w-6 h-6"/>
              <p className="text-[16px] font-bold text-[#023584]">Emotional Support</p>
            </div>
            <div className="flex gap-3" >
              <img src={PatientSafety} alt="" className="w-6 h-6"/>
              <p className="text-[16px] font-bold text-[#023584]">Enhanced patient safety</p>
            </div>
            <div className="flex gap-3" >
              <img src={QualifiedNurses} alt="" className="w-6 h-6"/>
              <p className="text-[16px] font-bold text-[#023584]">Highly qualified nurses</p>
            </div>
            <div className="flex gap-3" >
              <img src={PersonalCare} alt="" className="w-6 h-6" />
              <p className="text-[16px] font-bold text-[#023584]">Personal care assistance</p>
            </div>
            <div className="flex gap-3" >
              <img src={EmotionalSupport} alt="" className="w-6 h-6"/>
              <p className="text-[16px] font-bold text-[#023584]">Emotional Support</p>
            </div>
            </div>
          </div>
        </div> */}
        <div className="overflow-hidden">
          <div className="flex items-center  mt-8 letters  justify-center ">
            <div className="flex gap-12 letters-slide whitespace-nowrap">
              <div className="flex gap-3" >
                <img src={EmotionalSupport} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Emotional Support</p>
              </div>
              <div className="flex gap-3" >
                <img src={PatientSafety} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Enhanced patient safety</p>
              </div>
              <div className="flex gap-3" >
                <img src={QualifiedNurses} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Highly qualified nurses</p>
              </div>
              <div className="flex gap-3" >
                <img src={PersonalCare} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Personal care assistance</p>
              </div>
              <div className="flex gap-3" >
                <img src={EmotionalSupport} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Emotional Support</p>
              </div>
              <div className="flex gap-3" >
                <img src={PatientSafety} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Enhanced patient safety</p>
              </div>
              <div className="flex gap-3" >
                <img src={QualifiedNurses} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Highly qualified nurses</p>
              </div>
              <div className="flex gap-3" >
                <img src={PersonalCare} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Personal care assistance</p>
              </div>
              <div className="flex gap-3" >
                <img src={EmotionalSupport} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Emotional Support</p>
              </div>
              <div className="flex gap-3" >
                <img src={PatientSafety} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Enhanced patient safety</p>
              </div>
              <div className="flex gap-3" >
                <img src={QualifiedNurses} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Highly qualified nurses</p>
              </div>
              <div className="flex gap-3" >
                <img src={PersonalCare} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Personal care assistance</p>
              </div>
              <div className="flex gap-3" >
                <img src={EmotionalSupport} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Emotional Support</p>
              </div>
              <div className="flex gap-3" >
                <img src={PatientSafety} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Enhanced patient safety</p>
              </div>
              <div className="flex gap-3" >
                <img src={QualifiedNurses} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Highly qualified nurses</p>
              </div>
              <div className="flex gap-3" >
                <img src={PersonalCare} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Personal care assistance</p>
              </div>
              <div className="flex gap-3" >
                <img src={EmotionalSupport} alt="" className="w-6 h-6" />
                <p className="text-[16px] font-bold text-[#023584]">Emotional Support</p>
              </div>
            </div>
          </div>
        </div>



        {/* <div className="absolute w-[100px] h-[100px] bg-white bottom-0 right-0 rounded-tl-2xl "></div> */}
      </div>
      <section id="services" className="mt-12 md:mt-24">
        <Services />
      </section>
      <section className="mt-12">
        <NursingCare />
      </section>
      <section className="mt-24">
        <About />
      </section>
      <section className="mt-10">
        <BookAppointment />
      </section>
    </Layouts>
  );
};

export default Geri_care;
