import React, { useState } from "react";
import Medication from "../../../../Assets/Images/Medication.png";
import SkillNurse from "../../../../Assets/Images/NursingCare.png";
import Chronic from "../../../../Assets/Images/ChronicDisease.png";
import Rehabilation from "../../../../Assets/Images/Rehabilation.png";
import Arrow from "../../../../Assets/Images/RightUp-Arrow.png";
import BowArrow from "../../../../Assets/Images/icons8-bow-cupid.gif";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const [isHovered, setIsHovered] = useState("");
  const navigate = useNavigate();

  const handleReaMore = (route) => {
    navigate(route);
  };

  const handleArrowIn = (value) => {
    setIsHovered(value);
  };

  const handleArrowOut = () => {
    setIsHovered("");
  };

  return (
    <div>
      <div className=" flex flex-col items-center ">
        <p className="bg-gradient-to-r from-[#B5EBFF] to-[#f9fcff] px-3 mb-2 py-1  text-[14px] text-[#023584]  rounded-2xl">
          Discover the heart behind Geri<span className="italic">e</span>Care
        </p>
        <h2 className="md:text-[40px] text-xl text-[#023584] " style={{ fontWeight: 700 }}>
          Discover our <span className="text-[#266AFB]">services</span>
        </h2>
      </div>
      <div className="container w-[90%] mx-auto mt-16">
        <div className="grid md:grid-cols-4 grid-cols-1 gap-6 mb-8">
          <div
            className="bg-[#ECF2FF] relative rounded-2xl md:p-8 p-4"
            onMouseEnter={() => handleArrowIn("1")}
            onMouseLeave={() => handleArrowOut("")}
          >
            <div>
              <div>
                <img
                  src={Medication}
                  alt="Skilled Nurse"
                  className="h-16 w-16"
                />
              </div>
              <h3 className="font-bold md:text-[24px] text-xl text-[#023584] hover:text-[#014B11] mt-10">
                Respite Care

              </h3>
              <p className="mt-4 text-[#023584] mb-4">
              Respite care is short-term care provided to elderly individuals, giving their primary caregivers a break from their daily responsibilities. This service can be arranged for a few hours, days, or even weeks, depending on the needs of the family.
              </p>
            </div>
            <div className="absolute flex bottom-0 right-0 bg-white rounded-tl-xl p-2 cursor-pointer text-[#023584]" onClick={() => handleReaMore("/service-static")}>
              <p className="hover:text-[#014B11]">Read more</p>
              <img
                src={isHovered === "1" ? BowArrow : Arrow}
                alt="Arrow"
                className="w-3 h-3 mt-2 ml-1"
              />
            </div>
            {/* <div className="absolute w-[50px] h-[50px] bg-white top-0 right-0 rounded-bl-2xl "></div> */}
          </div>
          <div
            onMouseEnter={() => handleArrowIn("2")}
            onMouseLeave={() => handleArrowOut("")}
            className="bg-[#E4FFEA] relative rounded-2xl md:p-8 p-2"
          >
            <div>
              <div>
                <img src={SkillNurse} alt="" className="h-16 w-16" />
              </div>
              <h3 className="font-bold md:text-[24px] text-xl text-[#023584] hover:text-[#014B11] mt-10">
                Rehabilitative & Restorative Care

              </h3>
              <p className="mt-4 text-[#023584] mb-4">
              Rehabilitative and restorative care encompasses a range of services aimed at helping individuals regain their strength, mobility, and independence following an illness, injury, or surgical procedure.
              </p>
            </div>
            <div className="absolute flex bottom-0 right-0 bg-white rounded-tl-xl p-2 text-[#023584] cursor-pointer"  onClick={() => handleReaMore("/service-static")}>
              <p className="hover:text-[#014B11]">Read more</p>
              <img
                src={isHovered === "2" ? BowArrow : Arrow}
                alt="Arrow"
                className="w-3 h-3 mt-2 ml-1"
              />
            </div>
            {/* <div className="absolute w-[50px] h-[50px] bg-white top-0 right-0 rounded-bl-2xl "></div> */}
          </div>
          <div
            onMouseEnter={() => handleArrowIn("3")}
            onMouseLeave={() => handleArrowOut("")}
            className="bg-[#ECF2FF] relative rounded-2xl md:p-8 p-4"
          >
            <div>
              <div>
                <img src={Chronic} alt="" className="h-16 w-16" />
              </div>
              <h3 className="font-bold md:text-[24px] text-xl text-[#023584] hover:text-[#014B11] mt-10">
                Transitional Care
              </h3>
              <p className="mt-4 text-[#023584] mb-4">
              Transitional care involves coordinated care and support provided to patients as they move from one healthcare setting to another, such as from hospital to home or to a rehabilitation facility. 
              </p>
            </div>
            <div className="absolute flex bottom-0 right-0 bg-white rounded-tl-xl p-2 cursor-pointer text-[#023584]" onClick={() => handleReaMore("/service-static")}>
              <p className="hover:text-[#014B11]">Read more</p>
              <img
                src={isHovered === "3" ? BowArrow : Arrow}
                alt="Arrow"
                className="w-3 h-3 mt-2 ml-1"
              />
            </div>
            {/* <div className="absolute w-[50px] h-[50px] bg-white top-0 right-0 rounded-bl-2xl "></div> */}
          </div>
          <div
            onMouseEnter={() => handleArrowIn("4")}
            onMouseLeave={() => handleArrowOut("")}
            className="bg-[#E0F7FF] relative rounded-2xl md:p-8 p-4"
          >
            <div>
              <div>
                <img src={Rehabilation} alt="" className="h-16 w-16" />
              </div>
              <h3 className="font-bold md:text-[24px] text-xl text-[#023584] hover:text-[#014B11] mt-10">
                Palliative Care
              </h3>
              <p className="mt-4 text-[#023584] mb-4">
              Palliative care is specialized medical care aimed at improving the quality of life for individuals with serious illnesses.The goal is to alleviate suffering and provide comprehensive support to patients and their families.
              </p>
            </div>
            <div className="absolute flex bottom-0 right-0 bg-white rounded-tl-xl p-2 cursor-pointer text-[#023584]" onClick={() => handleReaMore("/service-static")}>
              <p className="hover:text-[#014B11]">Read more</p>
              <img
                src={isHovered === "4" ? BowArrow : Arrow}
                alt="Arrow"
                className="w-3 h-3 mt-2 ml-1"
              />
            </div>
            {/* <div className="absolute w-[50px] h-[50px] bg-white top-0 right-0 rounded-bl-2xl "></div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
