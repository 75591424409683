import React from "react";
import logo from "../../Assets/Images/logo5.png";
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";
import { RiTwitterXFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleRoute = (route) => {
    navigate(route);
  };

  return (
    <section className="md:mt-10">
      <div>
        <div className="md:flex md:p-9 p-4 gap-4">
          <div className="bg-[#ecf2ff] relative md:w-[30%] rounded-xl md:p-8 p-4">
            <div className="flex justify-center items-center">
              <img src={logo} alt="" className="h-8 w-8 md:h-16 md:w-16" />
              <h2 className="ml-2 font-bold md:text-[32px] text-[24px] text-[#0167A9] mr-16">
                Geri<span className="text-[#72c0f5] italic  font-medium">e</span>Care
              </h2>
            </div>
            <div className="md:mt-20">
              <p className="text-xl text-[#003588] my-2">
                Stay updated with our newsletter
              </p>
              <div className="flex">
                <input
                  className="p-4 text-sm rounded-full relative w-full border-none"
                  placeholder="Enter your email address"
                ></input>
                <button className="py-2 px-4 rounded-full my-2 font-semibold hover:bg-green-900 text-white absolute right-10 bg-[#003588]">
                  Subscribe
                </button>
              </div>
              <div className="flex text-2xl gap-3 mt-10">
                <a
                  href="https://www.facebook.com/login/"
                  className="text-[#003588]"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="https://www.instagram.com/accounts/login/?hl=en"
                  className="text-[#003588]"
                >
                  <AiFillInstagram />
                </a>
                <a
                  href="https://www.instagram.com/accounts/login/?hl=en"
                  className="text-[#003588]"
                >
                  <RiTwitterXFill />
                </a>
                <a
                  href="https://www.linkedin.com/login"
                  className="text-[#003588]"
                >
                  <BsLinkedin />
                </a>
              </div>
            </div>
            {/* <div className="h-[50px] lg:h-[75px] w-[50px] lg:w-[75px] absolute bg-white rounded-bl-2xl top-0 right-0"></div> */}
          </div>
          <div className="md:w-[70%] relative bg-[#e0f7ff] rounded-xl p-4 mt-4 md:mt-0">
            <div className="md:flex justify-evenly">
              <div>
                <h1 className=" text-xl font-bold text-[#003588] mt-4  md:mb-10">
                  Pages
                </h1>
                <div className="flex flex-col space-y-2 text-blue-600 text-lg">
                  <span
                    className="cursor-pointer"
                    onClick={() => handleRoute("/")}
                  >
                    Home
                  </span>
                  <span
                    className="cursor-pointer"
                    onClick={() => handleRoute("/about")}
                  >
                    About
                  </span>
                  <span
                    className="cursor-pointer"
                    onClick={() => handleRoute("/service")}
                  >
                    Service
                  </span>
                  <span
                    className="cursor-pointer "
                    onClick={() => handleRoute("/contact")}
                  >
                    Contact
                  </span>
                </div>
              </div>
              <div>
                {" "}
                <h1 className=" text-xl font-bold text-[#003588] mt-4 md:mb-10">
                  About Us
                </h1>
                <div className="flex flex-col space-y-2 text-blue-600 text-lg">
                  <a href="#" className="">
                    Blog
                  </a>
                  <a href="#">FAQs</a>
                  <a href="#">Privacy & Policy</a>
                  <a href="#">Terms & Condition</a>
                </div>
              </div>
              <div>
                <h1 className=" text-xl font-bold text-[#003588] mt-4 md:mb-10">
                  Contact
                </h1>
                <div className="flex flex-col space-y-2 text-blue-600 text-base">
                  <p className=" text-lg">Address</p>
                  <p className=" text-base">
                    #179, Kothari Nagar, Singanallur
                    <br />
                    Coimbatore - 641 005. INDIA
                  </p>
                  <p className="">Phone</p>
                  <p className=" text-base underline">+91 (824) 759 - 8623 </p>
                  <p className=" text-base">E-mail</p>
                  <a href="mailto:info@geriecare.com" className=" underline">
                    info@geriecare.com
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="h-[50px] lg:h-[75px] w-[50px] lg:w-[75px] absolute bg-white rounded-tl-2xl bottom-0 right-0"></div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;

