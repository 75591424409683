import React from "react";
import Layouts from "../../../Layouts";
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";
import { RiTwitterXFill } from "react-icons/ri";
import phone from "../../../../Assets/svg/phone.svg";
import mail from "../../../../Assets/svg/mail.svg";
import location from "../../../../Assets/svg/location.svg";
import time from "../../../../Assets/svg/time.svg";
import contactimg from "../../../../Assets/Images/contactimg.png";

function Contact() {
  return (
    <Layouts>
      <section className="md:mt-8  md:py-4 container md:w-[90%] p-4 md:p-0 mx-auto">
        <div className=" bg-[#ECF2FF] md:py-8 py-4 md:flex justify-center items-start rounded-md">
          <div className="md:w-1/2 md:px-12 px-2 text-[#003588] font-bold md:text-[40px] text-2xl">
            Connect with <span className="text-[#266AFB]">customer</span>
            <br /> support
          </div>
          <div className="md:w-1/2">
            <div className="md:w-[80%] md:p-0 p-4 text-[#003588]">
              Our dedicated customer support team is here to assist you with any
              questions, concerns, or feedback you may have.
            </div>
            <div className="flex md:text-lg text-sm gap-3 mt-10">
              <a
                href="https://www.facebook.com/login/"
                className="text-[#003588] bg-[#B2EAFF] p-4 rounded-full"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.instagram.com/accounts/login/?hl=en"
                className="text-[#003588] bg-[#B2EAFF] p-4 rounded-full"
              >
                <AiFillInstagram />
              </a>
              <a
                href="https://www.instagram.com/accounts/login/?hl=en"
                className="text-[#003588] bg-[#B2EAFF] p-4 rounded-full"
              >
                <RiTwitterXFill />
              </a>
              <a
                href="https://www.linkedin.com/login"
                className="text-[#003588] bg-[#B2EAFF] p-4 rounded-full"
              >
                <BsLinkedin />
              </a>
            </div>
          </div>
        </div>

        <div className="md:flex justify-between mt-10 gap-4 p-4 md:p-0 mx-auto font-semibold  text-[#003588]">
          <div className="md:w-[300px] rounded-md h-[200px] bg-[#E0F7FF] p-4  flex flex-col gap-2">
            <img alt="img" src={phone} className="w-14 h-14" />
            <div>Talk to our support experts</div>
            <div>+91 (824) 759 - 8623</div>
          </div>
          <div className="md:w-[300px] rounded-md h-[200px] bg-[#E0F7FF] p-4 flex flex-col gap-2 md:mt-0 mt-4">
            <img alt="img" src={mail} className="w-14 h-14" />
            <div>Send your queries</div>
            <div>info@geriecare.com</div>
          </div>
          <div className="md:w-[300px] rounded-md h-[200px] bg-[#E0F7FF] p-4 flex flex-col gap-2 md:mt-0 mt-4">
            <img alt="img" src={location} className="w-14 h-14" />
            <div>Where to find us</div>
            <div>#179, Kothari Nagar, Singanallur
            Coimbatore - 641 005. INDIA</div>
          </div>
          <div className="md:w-[300px] rounded-md h-[200px] bg-[#E0F7FF] p-4 flex flex-col gap-2 md:mt-0 mt-4">
            <img alt="img" src={time} className="w-14 h-14" />
            <div>Service hours</div>
            <div>Mon - Sat : 9.00 - 5.00</div>
          </div>
        </div>

        <div className=" md:flex justify-center gap-8 items-center md:mt-24 mt-10">
          <div className="md:w-1/2 flex justify-center items-center relative">
            {/* <div className="absolute w-[150px] h-[100px] bg-white top-0 left-0 rounded-br-2xl "></div> */}
            <img src={contactimg} alt="" className="md:h-[550px] rounded-md" />
          </div>
          <div className="bg-[#EDF2FB] md:p-8 p-4 rounded-md md:w-1/2 h-[550px] md:mt-0 mt-10">
            <form className="flex flex-col text-[#003588] font-bold">
              <div className="flex flex-col mt-8">
                <label for="name" className="">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="outline-none border p-1 rounded-md shadow-md my-2 px-4 h-[45px]"
                />
              </div>
              <div className="flex flex-col">
                <label for="phone">Phone no</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="outline-none border p-1 rounded-md shadow-md my-2 px-4 h-[45px]"
                />
              </div>
              <div className="flex flex-col">
                <label for="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="outline-none border p-1 rounded-md shadow-md my-2 px-4 h-[45px]"
                />
              </div>
              <div className="flex flex-col">
                <label for="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  className="outline-none border p-1 rounded-md shadow-md my-2 px-4 h-[80px]"
                ></textarea>
              </div>
              <div>
                <button className="bg-[#003588] text-white rounded-md p-2 w-full mt-8">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default Contact;
