import React from 'react';
import Layouts from '../../Layouts';
import Doctor1 from "../../../Assets/Images/Doctor1.png";

const DoctorProfile = () => {
  return (
    <Layouts>
    <div className="container mx-auto p-4">
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/3 flex flex-col justify-center md:justify-start">
            <img
              className="rounded-full w-40 h-40"
              src={Doctor1}
              alt="Dr. Dillip Kumar Mohanty"
            />
                        <p className="text-gray-700 mt-2">
              <span className="font-semibold">Qualification:</span> MBBS, DNB (GASTROENTEROLOGY)
            </p>
            <p className="text-gray-700 mt-2">
              <span className="font-semibold">Experience:</span> 10 Years
            </p>
            <p className="text-gray-700 mt-2">
              <span className="font-semibold">Location:</span> Bhubaneswar
            </p>
            
          </div>
          {/* <div className="md:w-2/3 mt-4 md:mt-0 md:pl-6">
            <h1 className="text-2xl font-bold">Dr. DILLIP KUMAR MOHANTY</h1>
            <p className="text-gray-700 mt-2">
              <span className="font-semibold">Qualification:</span> MBBS, DNB (GASTROENTEROLOGY)
            </p>
            <p className="text-gray-700 mt-2">
              <span className="font-semibold">Experience:</span> 10 Years
            </p>
            <p className="text-gray-700 mt-2">
              <span className="font-semibold">Location:</span> Bhubaneswar
            </p>
            <div className="mt-4">
              <h2 className="text-xl font-semibold">About the Doctor</h2>
              <p className="text-gray-700 mt-2">
                Dr. Dillip Kumar Mohanty is MD(Medicine), DNB(Gastroenterology) and fellowship in Advanced Endoscopy, ERCP and EUS. He is renowned gastroenterologist of Bhubaneswar. He has a rich experience of more than 10 years. Currently, Dr. Dillip is associated with care hospital. He also practices at Ketaki Superspeciality GASTRO-LIVER clinic.
              </p>
            </div>
          </div> */}
                    <div className="mt-4 md:w-full">
                    <h1 className="text-2xl font-bold mb-3">Dr. DILLIP KUMAR MOHANTY</h1>
                    <h2 className="text-xl font-semibold">Book an Appointment</h2>
                    <p className="text-green-500 text-lg font-bold my-2">₹ 500</p>
            <div className="flex justify-between">
              <button className="px-4 py-2 border rounded-md">01 Aug 2024 [Today]</button>
              <button className="px-4 py-2 border rounded-md">02 Aug 2024 [Fri]</button>
              <button className="px-4 py-2 border rounded-md">03 Aug 2024 [Sat]</button>
            </div>
            <div className="mt-4">
              <h3 className="text-lg font-semibold">Afternoon Slots</h3>
              <div className="flex flex-wrap gap-2 mt-2">
                <button className="px-4 py-2 border rounded-md">03:00 PM</button>
                <button className="px-4 py-2 border rounded-md">03:15 PM</button>
                <button className="px-4 py-2 border rounded-md">03:30 PM</button>
                <button className="px-4 py-2 border rounded-md">03:45 PM</button>
                <button className="px-4 py-2 border rounded-md">04:00 PM</button>
              </div>
              <h3 className="text-lg font-semibold mt-4">Evening Slots</h3>
              <div className="flex flex-wrap gap-2 mt-2">
                <button className="px-4 py-2 border rounded-md">07:00 PM</button>
                <button className="px-4 py-2 border rounded-md">07:15 PM</button>
                <button className="px-4 py-2 border rounded-md">07:30 PM</button>
                <button className="px-4 py-2 border rounded-md">07:45 PM</button>
                <button className="px-4 py-2 border rounded-md">08:00 PM</button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          {/* <h2 className="text-xl font-semibold">Book an Appointment</h2>
          <p className="text-green-500 text-lg font-bold mt-2">₹ 500</p> */}
          {/* <div className="mt-4">
            <div className="flex justify-between">
              <button className="px-4 py-2 border rounded-md">01 Aug 2024 [Today]</button>
              <button className="px-4 py-2 border rounded-md">02 Aug 2024 [Fri]</button>
              <button className="px-4 py-2 border rounded-md">03 Aug 2024 [Sat]</button>
            </div>
            <div className="mt-4">
              <h3 className="text-lg font-semibold">Afternoon Slots</h3>
              <div className="flex flex-wrap gap-2 mt-2">
                <button className="px-4 py-2 border rounded-md">03:00 PM</button>
                <button className="px-4 py-2 border rounded-md">03:15 PM</button>
                <button className="px-4 py-2 border rounded-md">03:30 PM</button>
                <button className="px-4 py-2 border rounded-md">03:45 PM</button>
                <button className="px-4 py-2 border rounded-md">04:00 PM</button>
              </div>
              <h3 className="text-lg font-semibold mt-4">Evening Slots</h3>
              <div className="flex flex-wrap gap-2 mt-2">
                <button className="px-4 py-2 border rounded-md">07:00 PM</button>
                <button className="px-4 py-2 border rounded-md">07:15 PM</button>
                <button className="px-4 py-2 border rounded-md">07:30 PM</button>
                <button className="px-4 py-2 border rounded-md">07:45 PM</button>
                <button className="px-4 py-2 border rounded-md">08:00 PM</button>
              </div>
            </div>
          </div> */}
          <div className="md:w-2/3 mt-4 md:mt-0 md:pl-6">
            
            {/* <p className="text-gray-700 mt-2">
              <span className="font-semibold">Qualification:</span> MBBS, DNB (GASTROENTEROLOGY)
            </p>
            <p className="text-gray-700 mt-2">
              <span className="font-semibold">Experience:</span> 10 Years
            </p>
            <p className="text-gray-700 mt-2">
              <span className="font-semibold">Location:</span> Bhubaneswar
            </p> */}
            <div className="mt-4">
              <h2 className="text-xl font-semibold">About the Doctor</h2>
              <p className="text-gray-700 mt-2">
                Dr. Dillip Kumar Mohanty is MD(Medicine), DNB(Gastroenterology) and fellowship in Advanced Endoscopy, ERCP and EUS. He is renowned gastroenterologist of Bhubaneswar. He has a rich experience of more than 10 years. Currently, Dr. Dillip is associated with care hospital. He also practices at Ketaki Superspeciality GASTRO-LIVER clinic.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Layouts>
  );
};

export default DoctorProfile;