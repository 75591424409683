import React from "react";
import oldchine from "../../../Assets/Images/oldchine.jpg";

function JoinCommunity() {
  return (
    <div>
      {/* <div className="mt-20 relative container w-[90%] mx-auto">
         <div className="rounded-2xl py-24 flex flex-col items-center " style={{ backgroundImage: `url(${oldchine})` , filter:'brightness(75`%)'}}>
          <h2
            className="text-[40px] text-white "
            style={{ fontWeight: 700 }}
          >
            Join our community of care
          </h2>
          <div className="flex justify-center items-center mt-6">
            <button className="bg-[#014B11] hover:bg-[#023584] text-white px-3 py-2 text-lg rounded-3xl ml-2">
              Schedule a Consultation
            </button>
          </div>
        </div>
        <div className="absolute w-[100px] h-[100px] bg-white top-0 left-0 rounded-br-2xl "></div>
        <div className="absolute w-[100px] h-[100px] bg-white bottom-0 right-0 rounded-tl-2xl "></div>
      </div> */}
      <div className="md:mt-20 relative container w-[90%] mx-auto">
        <div
          className="rounded-2xl md:py-24 w-full flex flex-col items-center bg-cover bg-center"
          style={{
            backgroundImage: `url(${oldchine})`,
            filter: "brightness(90%)",
          }}
        >
          <h2
            className="md:text-[30px] text-[40px] text-white text-center"
            style={{ fontWeight: 700 }}
          >
            Join our community of care
          </h2>
          <div className="flex justify-center items-center mt-6">
            <button className="bg-[#014B11] hover:bg-[#023584] text-white px-4 mb-2  py-2  text-[14px] md:text-lg rounded-3xl ml-2">
              Schedule a Consultation
            </button>
          </div>
        </div>
        {/* <div className="absolute w-[100px] h-[100px] bg-white top-0 left-0 rounded-br-2xl "></div>
  <div className="absolute w-[100px] h-[100px] bg-white bottom-0 right-0 rounded-tl-2xl "></div> */}
      </div>
    </div>
  );
}

export default JoinCommunity;
