import React from "react";
import Layouts from "../../../Layouts";
import AboutBG from "../../../../Assets/Images/AboutBg.png";
import Nurses from "../../../../Assets/Images/Nurses.png";
import Target from "../../../../Assets/Images/Target.png";
import Gramdma from "../../../../Assets/Images/Grandma.png";
import caring from "../../../../Assets/Images/Caring.png";
import Tick from "../../../../Assets/Images/Tick.png";
import Team1 from "../../../../Assets/Images/Team1.png";
import Team2 from "../../../../Assets/Images/Team2.png";
import Team3 from "../../../../Assets/Images/Team3.png";
import Team4 from "../../../../Assets/Images/Team4.png";

function About() {
  const stats = [
    { number: "300", label: "Patients served" },
    { number: "30", label: "Caregivers" },
    { number: "10", label: "Services offered" },
    { number: "95%", label: "Client satisfaction" },
  ];

  return (
    <Layouts>
      <div className="container md:w-[90%] md:p-0 p-4 mx-auto">
        <div className="relative">
          <div className="rounded-2xl brightness-50 relative overflow-hidden">
            <img src={AboutBG} alt="" className=" w-full h-full object-cover rounded-2xl hover:scale-110 duration-700" />
          </div>
          <div className="absolute md:left-60 md:top-44 top-0 ">
            <div className="text-center text-white">
              <h2 className="md:text-[55px] test-sm mb-2 p-4" style={{ fontWeight: 700 }}>
                About <span className="text-white">Geri<span className="text-white italic  font-medium">e</span>Care</span>
              </h2>
              <p className="text-[16px]">
              Welcome to Geri<span className="italic">e</span>Care
              , where our mission is to provide exceptional and compassionate care for the elderly. 
              </p>
            </div>
          </div>
          {/* <div className="absolute w-[100px] h-[100px] bg-white bottom-0 right-0 rounded-tl-2xl "></div> */}
        </div>
        <div className="md:flex gap-8 mt-10 md:mt-20">
          <div className="md:w-1/2 ">
            <h2
              className="md:text-[40px] text-[22px] md:leading-[50px] mb-2 text-[#023584]"
              style={{ fontWeight: 700 }}
            >
               Providing compassionate home{" "}
              <span className="text-[#266AFB]">Geri<span className="text-[#266AFB] italic  font-medium">e</span>Care</span> 
            </h2>
            <p className="text-[#406397]">
            Our team of experienced caregivers, nurses, and support staff are dedicated to providing compassionate care. They are trained to handle a wide range of medical and personal care needs, ensuring that your loved one is in good hands.
            </p>
            <img src={Target} alt="" className="md:mt-10 h-10 w-10" />
            <h4
              className="md:text-[28px] mb-2 text-xl text-[#023584] font-normal "
              style={{ fontWeight: 500 }}
            >
              Our mission
            </h4>
            <p className="text-[#406397]">
              At Geri<span className="italic">e</span>Care, our mission is to deliver high-quality, compassionate care that promotes the well-being and dignity of elderly individuals.
            </p>
          </div>
          <div className="md:w-1/2 overflow-hidden rounded-2xl md:py-0 py-4">
            <img src={Nurses} alt=""  className="hover:scale-105 hover:rotate-3 duration-700"/>
          </div>
        </div>
        <div className="md:mt-20 relative  rounded-2xl md:py-8 py-4 bg-[#e0f7ff] flex flex-col items-center">
          <h2
            className="md:text-[40px] text-xl text-[#023584] "
            style={{ fontWeight: 700 }}
          >
            Join our community of care
          </h2>
          <div className="flex flex-col items-center mt-4">
            <p className="text-[#023584] text-[14px]">
              At Geri<span className="italic">e</span>Care, we're here to provide compassionate and
              personalized care{" "}
            </p>
            <p className="text-[#023584] text-[14px]">
              {" "}
              to you or your loved ones.
            </p>
          </div>
          <div className="flex justify-center items-center mt-6">
            <button className="hover:bg-[#014B11] bg-[#023584] w-24 text-white px-2 py-2 text-[14px] rounded-3xl ml-2 mt-1">
              Get started
            </button>
          </div>
          {/* <div className="absolute w-[100px] h-[100px] bg-white top-0 left-0 rounded-br-2xl "></div> */}
          {/* <div className="absolute w-[100px] h-[100px] bg-white bottom-0 right-0 rounded-tl-2xl "></div> */}
        </div>
        <div className="md:flex md:mt-20 mt-10">
          <div className="md:w-1/2 flex gap-8 relative">
            <div className="flex items-end">
              <div className="">
                <img src={Gramdma} alt="" />
              </div>
            </div>
            <div className="md:mt-8">
              <img src={caring} alt="" className="rounded-2xl" />
            </div>
          </div>
          <div className="md:w-1/2">
            <div className="md:pl-20">
              <h2
                className="md:text-[50px] text-xl text-[#023584] "
                style={{ fontWeight: 700 }}
              >
                Our value
              </h2>
              <p className="text-[18px] text-[#023584] mt-4">
                Our values guide everything we do. We believe in Compassion,
                Integrity, Excellence, Respect, and Innovation.
              </p>
              <ul className=" text-[#023584] mt-6 ">
                <li className="flex gap-3 ">
                  <img src={Tick} alt="" className="h-6 w-6" />
                  <p>We provide care with empathy and understanding, treating every individual with kindness and respect.</p>
                </li>
                <li className="flex gap-3 mt-4">
                  <img src={Tick} alt="" className="h-6 w-6" />
                  <p>We uphold the highest standards of honesty and transparency in all our interactions and services.</p>
                </li>
                <li className="flex gap-3 mt-4">
                  <img src={Tick} alt="" className="h-6 w-6" />
                  <p>We honour the dignity, individuality, and rights of every person we care for, fostering an environment of mutual respect.</p>
                </li>
                <li className="flex gap-3 mt-4">
                  <img src={Tick} alt="" className="h-6 w-6" />
                  <p>We work together with clients, families, and healthcare professionals to deliver coordinated and comprehensive care.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="md:my-10 my-6">
          <div className="md:py-6 py-4 p-4 md:p-0 ">
            <div className="grid grid-cols-4 gap-8 text-center">
              {stats.map((stat, index) => (
                <div key={index} className="flex flex-col items-center">
                  <span className="md:text-[50px] mb-2 text-2xl font-bold text-indigo-900">
                    {stat.number}
                  </span>
                  <span className="text-blue-500 md:text-lg text-sm ">{stat.label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <h2
            className="md:text-[50px] text-2xl text-[#023584] "
            style={{ fontWeight: 500 }}
          >
            Meet our dedicated <span className="text-[#266AFB]">team</span>
          </h2>
          <div className="md:grid grid-cols-4 gap-10 py-8">
            <div className="text-center">
              <div className="relative overflow-hidden rounded-2xl">
                <img
                  src={Team1}
                  alt="Michael Wilson"
                  className="w-full h-full object-cover duration-700 hover:rotate-6  hover:scale-110"
                />
              </div>
              <h3 className="text-[24px] font-semibold text-[#023584] mx-4">
                Michael Wilson, RN
              </h3>
              <p className="text-[#023684] font-normal text-[18px]">
                Senior Nurse
              </p>
            </div>
            <div className="text-center">
              <div className="relative overflow-hidden rounded-2xl">
                <img
                  src={Team2}
                  alt="Michael Wilson"
                  className="w-full h-full object-cover duration-700 hover:rotate-6  hover:scale-110"
                />
              </div>
              <h3 className="text-[24px] font-semibold text-[#023584] mx-4">
                Susie Beats, LPN
              </h3>
              <p className="text-[#023684] font-normal text-[18px]">
                Care Coordinator
              </p>
            </div>
            <div className="text-center">
              <div className="relative overflow-hidden rounded-2xl">
                <img
                  src={Team3}
                  alt="Michael Wilson"
                  className="w-full h-full object-cover duration-700 hover:rotate-6  hover:scale-110"
                />
              </div>
              <h3 className="text-[24px] font-semibold text-[#023584] mx-4">
                Serina Williams, RN
              </h3>
              <p className="text-[#023684] font-normal text-[18px]">
                Palliative Care
              </p>
            </div>
            <div className="text-center">
              <div className="relative overflow-hidden rounded-2xl">
                <img
                  src={Team4}
                  alt="Michael Wilson"
                  className="w-full h-full object-cover duration-700 hover:rotate-6  hover:scale-110"
                />
              </div>
              <h3 className="text-[24px] font-semibold text-[#023584] mx-4">
                Junitha Caarter, RN
              </h3>
              <p className="text-[#023684] font-normal text-[18px]">
                Nursing Assistant
              </p>
            </div>

          </div>
        </div>
      </div>
    </Layouts>
  );
}

export default About;

{
  /* <div className="my-14">
  <div className=" py-16">
    <div className="max-w-6xl mx-auto px-4">
      <div className="grid grid-cols-4 gap-8 text-center">
        {stats.map((stat, index) => (
          <div key={index} className="flex flex-col items-center">
            <span className="text-4xl font-bold text-blue-800">
              {stat.number}
            </span>
            <span className="text-blue-600 mt-2">{stat.label}</span>
          </div>
        ))}
      </div>
    </div>
  </div>
</div>; */
}
